import get from "lodash/get";
import isNull from "lodash/isNull";

export function getSubstring(string, limit) {
  let slicedString = string.substr(0, limit);
  return string.length > limit ? slicedString.substr(0, slicedString.lastIndexOf(" ")) + "..." : string;
}

export function collectionColor(config, collection) {
  const sectionId =
    collection.template === "section"
      ? get(collection.metadata, ["section", "id"]) || get(collection.metadata, ["section", 0, "id"])
      : null;
  const menuItem = config.layout.menu.find(
    menuItem => menuItem["item-type"] === "section" && menuItem["item-id"] === sectionId
  );
  return get(menuItem, ["data", "color"], "#ed5564");
}

export function sectionColor(config, sectionId) {
  const menuItem = get(config, ["layout", "menu"]).find(
    menuItem => menuItem["item-type"] === "section" && menuItem["item-id"] === sectionId
  );
  return get(menuItem, ["data", "color"], "#ed5564");
}

export function collectionSection(config, collection) {
  const sectionId =
    collection.template === "section"
      ? get(collection.metadata, ["section", "id"]) || get(collection.metadata, ["section", 0, "id"])
      : null;
  return config.sections.find(section => section.id === sectionId);
}

export function refreshAdSlots() {
  window.googletag.pubads().refresh();
}

export function changeHttpToHttps(url) {
  let itemUrl = url;
  if (itemUrl && itemUrl.indexOf("http://") !== -1) {
    itemUrl = itemUrl.replace("http://", "https://");
  }
  return itemUrl;
}

export const getParentSection = ({ collection, config }) => {
  let parentSection = null;
  const sectionId = get(collection, ["metadata", "section", "0", "id"], null);
  const allSections = get(config, ["config", "sections"], []);
  if (sectionId && allSections.length) {
    const currentSection = allSections.find(o => o.id === sectionId);
    if (currentSection && !isNull(currentSection["parent-id"])) {
      parentSection = allSections.find(o => o.id === currentSection["parent-id"]);
      if (parentSection) return parentSection;
    }
  }
  return null;
};

export const storyShareClickHandler = (storyContentId, storyUrl) => event => {
  return (
    global.app &&
    global.app.registerStoryShare &&
    global.app.registerStoryShare(storyContentId, event.currentTarget.getAttribute("data-social-media-type"), storyUrl)
  );
};
