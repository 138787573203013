import React from "react";
import PropTypes from "prop-types";

export function ShareIcon({ color = "#d0021b", width = 21, height = 21 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 21 21">
      <g fill="none">
        <path fill="rgba(255,255,255,0)" d="M0 0h24v24H0z" />
        <path
          fill={color}
          d="M15.681 4c-1.515 0-2.758 1.261-2.758 2.8 0 .298.047.591.133.862l-4.554 2.57a2.715 2.715 0 0 0-1.73-.632c-1.516 0-2.758 1.261-2.758 2.8s1.242 2.8 2.758 2.8a2.71 2.71 0 0 0 1.73-.626l4.56 2.571a2.79 2.79 0 0 0-.139.855c0 1.539 1.243 2.8 2.758 2.8s2.758-1.261 2.758-2.8-1.243-2.8-2.758-2.8c-.761 0-1.455.323-1.956.835L9.291 13.53a2.79 2.79 0 0 0 0-2.262l4.428-2.504a2.732 2.732 0 0 0 1.962.835c1.515 0 2.758-1.261 2.758-2.8S17.196 4 15.68 4z"
        />
      </g>
    </svg>
  );
}

ShareIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};
