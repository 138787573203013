import React from "react";
import PT from "prop-types";
import "./section-name.m.css";
const SectionName = ({
  name = "",
  underlinePosition = "left",
  isDark,
  inlineStyles = {},
  className = "",
  isExtraSmall = false
}) => {
  const styleNameColor = isDark ? "section-name--dark" : "";
  const underline = underlinePosition === "center" ? "underline--center" : "";
  const size = isExtraSmall ? "section-name--xs" : "section-name";
  return name ? (
    <p styleName={`${styleNameColor} ${underline} ${size}`} style={inlineStyles} className={className}>
      {name}
    </p>
  ) : null;
};

SectionName.propTypes = {
  name: PT.string.isRequired,
  underlinePosition: PT.oneOf(["left", "center"]),
  isDark: PT.bool,
  inlineStyles: PT.object,
  className: PT.string,
  isExtraSmall: PT.bool
};

export { SectionName };
