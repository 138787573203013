import React from "react";
import Carousel from "../components/atoms/carousel";
import PT from "prop-types";
import "./breaking-news-view.m.css";
import { Instagram } from "../components/atoms/icons/instagram";
import { Facebook } from "../components/atoms/icons/facebook";
import { Linkedin } from "../components/atoms/icons/linkedin";
import { Twitter } from "../components/atoms/icons/twitter";
import get from "lodash/get";
import { Link } from "@quintype/components";

function TrendingNewsCarousel(props) {
  const socialLinks = get(props, ["config", "social-links"], {});
  const CAROUSEL_OPTIONS = {
    type: "carousel",
    autoplay: 5000
  };
  const collectionId = props.collection ? props.collection.id : "";
  const stories = props.stories;

  return (
    <div styleName="breaking-news-container">
      <div styleName="breaking-news">
        <div styleName="trending-heading">Trending</div>
        <div styleName="arrow-right" />
        <div styleName="carousel">
          <Carousel styleName="trending-carousel" options={CAROUSEL_OPTIONS} carouselName={"carousel-" + collectionId}>
            {stories &&
              stories.slice(0, 10).map(story => (
                <Link href={`/${story.slug}`} key={story.id}>
                  {story.headline}
                </Link>
              ))}
          </Carousel>
        </div>
      </div>
      <div styleName="social-icons">
        {socialLinks["facebook-url"] && (
          <a styleName={`icon`} target="_blank" rel="noopener noreferrer" href={socialLinks["facebook-url"]}>
            <Facebook width="6" height="13" color="grey" />
          </a>
        )}
        {socialLinks["twitter-url"] && (
          <a styleName={`icon`} target="_blank" rel="noopener noreferrer" href={socialLinks["twitter-url"]}>
            <Twitter width="12" height="10" color="grey" />
          </a>
        )}
        <a
          styleName={`icon`}
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/showcase/sme10x"
        >
          <Linkedin width="13" height="10" color="grey" />
        </a>
        {socialLinks["instagram-url"] && (
          <a styleName={`icon`} target="_blank" rel="noopener noreferrer" href={socialLinks["instagram-url"]}>
            <Instagram width="11.4" height="11.4" color="grey" />
          </a>
        )}
      </div>
    </div>
  );
}

TrendingNewsCarousel.propTypes = {
  breakingNews: PT.array,
  breakingNewsLoaded: PT.bool,
  socialLinks: PT.object,
  collection: PT.object,
  stories: PT.array
};

export { TrendingNewsCarousel };
