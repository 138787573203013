import React from "react";
import "./styles.m.css";
import get from "lodash/get";

const styles = {
  small: { rectangle: "rectangle--small", triangle: "triangle--small" },
  large: { rectangle: "rectangle", triangle: "triangle" }
};

function getStyleName(type) {
  return get(styles, type);
}

const PlayButton = ({ type }) => (
  <div styleName={`rectangle ${getStyleName(type).rectangle}`}>
    <div styleName={`triangle ${getStyleName(type).triangle}`} />
  </div>
);

export { PlayButton };
