import React from "react";
import { number } from "prop-types";

export function NextButton({ width = 74, height = 74 }) {
  return (
    <svg
      height={height}
      viewBox="0 0 74 74"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          id="a"
          d="m21.3567671 5-4.3567671 4.46742819 30.6663478 27.53257181-30.6663478 27.5341012 4.3567671 4.4658988 35.6432329-32z"
        />
        <filter id="b" height="200%" width="200%" x="-50%" y="-50%">
          <feOffset dx="0" dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
          <feColorMatrix in="shadowBlurOuter1" type="matrix" values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.4 0" />
        </filter>
      </defs>
      <g fill="#5a5a5a" fillRule="evenodd">
        <use fill="#5a5a5a" filter="url(#b)" xlinkHref="#a" />
        <use fill="#5a5a5a" fillRule="evenodd" xlinkHref="#a" />
      </g>
    </svg>
  );
}

NextButton.propTypes = {
  width: number,
  height: number
};
