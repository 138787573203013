import React from "react";
import "./styles.m.css";
import PropTypes from "prop-types";

function Author(props) {
  let authorName = props.authorName;
  return (
    <p styleName="author" className="author-name">
      {" "}
      {`by ${authorName}`}{" "}
    </p>
  );
}

export { Author };

Author.propTypes = {
  authorName: PropTypes.string
};
