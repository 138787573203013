import React from "react";
import { Facebook } from "../../atoms/icons/facebook";
import { Twitter } from "../../atoms/icons/twitter";
import { Linkedin } from "../../atoms/icons/linkedin";
import { CloseIcon } from "../../atoms/icons/close-icon";
import { ShareIcon } from "../../atoms/icons/share-icon";
import "./social-share-hover.m.css";
import { PropTypes } from "prop-types";

export class SocialShareTemplateHover extends React.Component {
  constructor() {
    super();
    this.state = {
      isShareButtonOpen: false
    };
    this.toggleShareButtonState = this.toggleShareButtonState.bind(this);
  }

  toggleShareButtonState(e) {
    e.preventDefault();
    this.setState({
      isShareButtonOpen: !this.state.isShareButtonOpen
    });
  }

  render() {
    const logoDimensions = {
      width: 15,
      height: 15
    };
    return (
      <ul styleName={`social-share ${this.state.isShareButtonOpen ? "social-share--open" : ""}`}>
        {!this.state.isShareButtonOpen ? (
          <li styleName="social-share__icon">
            <a onClick={this.toggleShareButtonState} styleName="social-share__link">
              <ShareIcon width={logoDimensions.width} height={logoDimensions.height} color={"#fff"} />
            </a>
          </li>
        ) : (
          <React.Fragment>
            <li styleName="social-share__icon">
              <a href={this.props.linkedinUrl} target="_blank" rel="noopener noreferrer" styleName="social-share__link">
                <Linkedin width={logoDimensions.width} height={logoDimensions.height} color={this.props.color} />
              </a>
            </li>
            <li styleName="social-share__icon">
              <a href={this.props.twitterUrl} target="_blank" rel="noopener noreferrer" styleName="social-share__link">
                <Twitter width={logoDimensions.width} height={logoDimensions.height} color={this.props.color} />
              </a>
            </li>
            <li styleName="social-share__icon">
              <a href={this.props.fbUrl} target="_blank" rel="noopener noreferrer" styleName="social-share__link">
                <Facebook width={logoDimensions.width} height={logoDimensions.height} color={this.props.color} />
              </a>
            </li>
            <li styleName="social-share__icon">
              <a
                onClick={this.toggleShareButtonState}
                href={this.props.fbUrl}
                target="_blank"
                rel="noopener noreferrer"
                styleName="social-share__link"
              >
                <CloseIcon width={logoDimensions.width} height={logoDimensions.height} color={this.props.color} />
              </a>
            </li>
          </React.Fragment>
        )}
      </ul>
    );
  }
}

SocialShareTemplateHover.propTypes = {
  fbUrl: PropTypes.string,
  twitterUrl: PropTypes.string,
  linkedinUrl: PropTypes.string,
  color: PropTypes.string
};
