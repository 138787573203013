import React from "react";
/* eslint-disable object-shorthand */
import { createDfpAdComponent } from "@quintype/components";
import get from "lodash/get";
import "./dfp-ad.m.css";

const viewPortSizeMappingHorizontal728to320 = [
  { viewport: [980, 90], sizes: [[728, 90]] },
  { viewport: [320, 0], sizes: [[320, 50]] },
  { viewport: [0, 0], sizes: [[320, 50]] }
];
const viewPortSizeMappingVertical600to250 = [
  { viewport: [980, 90], sizes: [[300, 600]] },
  { viewport: [320, 0], sizes: [[300, 250]] },
  { viewport: [0, 0], sizes: [[300, 250]] }
];
const viewPortSizeMappingHorizontal140to60 = [
  { viewport: [768, 0], sizes: [[140, 90]] },
  { viewport: [300, 0], sizes: [[60, 40]] }
];
const viewPortSizeMappingHorizontal1000to300 = [
  { viewport: [768, 0], sizes: [[1000, 250]] },
  { viewport: [300, 0], sizes: [[300, 250]] }
];
const viewPortSizeMappingHorizontal728to300 = [
  { viewport: [768, 0], sizes: [[728, 90]] },
  { viewport: [300, 0], sizes: [[300, 250]] }
];

export const CONFIG = {
  "Horizontal-Ad": {
    adUnit: "Horizontal_Responsive",
    sizes: [[728, 90], [320, 50]],
    viewPortSizeMapping: viewPortSizeMappingHorizontal728to320
  },
  "Vertical-Ad": {
    adUnit: "Vertical_Responsive",
    sizes: [[300, 600], [300, 250]],
    viewPortSizeMapping: viewPortSizeMappingVertical600to250
  },
  "Vertical-Ad-Bottom": {
    adUnit: "Vertical_Responsive_Bottom",
    sizes: [[300, 600], [300, 250]],
    viewPortSizeMapping: viewPortSizeMappingVertical600to250
  },
  SponsoredLogo: {
    adUnit: "SponsoredLogo",
    sizes: [[60, 40], [140, 90]],
    viewPortSizeMapping: viewPortSizeMappingHorizontal140to60
  },
  SponsoredBio: {
    adUnit: "SponsoredBio",
    sizes: [[300, 250], [1000, 250]],
    viewPortSizeMapping: viewPortSizeMappingHorizontal1000to300
  },
  StorySponsoredBio: {
    adUnit: "StorySponsoredBio",
    sizes: [[300, 250], [1000, 250]],
    viewPortSizeMapping: viewPortSizeMappingHorizontal1000to300
  },
  instoryad: {
    adUnit: "instoryad",
    sizes: [[300, 250], [728, 90]],
    viewPortSizeMapping: viewPortSizeMappingHorizontal728to300
  },
  Mrec: { adUnit: "Mrec", sizes: [[300, 250]] }
};

const getPageName = state => {
  const pagetype = get(state, ["qt", "pageType"]);
  if (pagetype === "home-page") {
    return "home";
  } else if (pagetype === "section-page") {
    return get(state, ["qt", "data", "collection", "slug"]);
  } else if (pagetype === "story-page") {
    return get(state, ["qt", "data", "story", ["story-template"]]);
  }
};

const getStorySectionName = state => {
  const pagetype = get(state, ["qt", "pageType"]);
  if (pagetype === "story-page") {
    return get(state, ["qt", "data", "story", "sections", 0, "name"]);
  }
};

const getTags = state => {
  const pagetype = get(state, ["qt", "pageType"]);
  if (pagetype === "story-page") {
    const tags = get(state, ["qt", "data", "story", "tags"], []);
    const tagSlug = tags.map(tag => tag.slug);
    return tagSlug;
  }
};

const getComponentName = state => {
  const pagetype = get(state, ["qt", "pageType"]);
  if (pagetype === "home-page") {
    return "industry";
  }
  return "BlankStory";
};

const DfpAdContent = createDfpAdComponent({
  defaultNetworkID: "67495243",
  config: CONFIG,

  targeting: function(state, props) {
    const params = {
      environment: get(state, ["qt", "config", ["publisher-attributes"], "env"]),
      pageType: get(state, ["qt", "pageType"]),
      publisherName: get(state, ["qt", "config", "publisher-name"]),
      publisherId: get(state, ["qt", "config", "publisher-id"]) || 571,
      pageName: getPageName(state),
      storySection: getStorySectionName(state),
      tag: getTags(state),
      componentName: getComponentName(state),
      sectionName: (props && props.sectionName) || "section"
    };
    if (
      get(state, ["qt", "pageType"]) === "story-page" &&
      get(state, ["qt", "data", "story", "metadata", "sponsored-by"])
    ) {
      params.sponsor = get(state, ["qt", "data", "story", "metadata", "sponsored-by"]);
      return params.sponsor;
    }
    return params;
  }
});

export const DfpAd = function(props) {
  return (
    <div styleName="ad" className="dfp-ad">
      <DfpAdContent {...props} />
    </div>
  );
};
