// Update the carousel as and when required.
// Make sure to keep the props contract same as nuka carousel

import React, { Component } from "react";
import PropTypes from "prop-types";
import Glide from "@glidejs/glide";
import { NextButton } from "../../atoms/icons/next-button";
import { PreButton } from "../../atoms/icons/pre-button";

// TODO: The CSS styles from glide is still importing in app.scss. We've to find a better way to incorporate that.
import "./carousel.m.css";
import { LazyLoadImages } from "@quintype/components";

export default class Carousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0
    };
    this.nextSlide = this.nextSlide.bind(this);
    this.previousSlide = this.previousSlide.bind(this);
    this.goToSlide = this.goToSlide.bind(this);
  }

  componentDidMount() {
    if (this.props.children) {
      setTimeout(() => {
        this.glide = new Glide("." + this.props.carouselName, this.props.options).mount();
        this.glide.on(["mount.after", "move.after"], () => {
          this.setState({ currentSlide: this.glide.index });
        });
      }, 300);
    }
  }

  componentWillUnmount() {
    if (this.props.children && this.glide) {
      this.glide.destroy();
    }
  }

  renderChildren() {
    return this.props.children.map((child, index) => {
      return index === 0 ? (
        <li key={index} className="glide__slide">
          {child}
        </li>
      ) : (
        <li key={index} className="glide__slide">
          <LazyLoadImages>{child}</LazyLoadImages>
        </li>
      );
    });
  }

  nextSlide() {
    this.glide.go(">");
  }

  previousSlide() {
    this.glide.go("<");
  }

  goToSlide(index) {
    this.glide.go("=" + index);
  }

  render() {
    if (!this.props.children) {
      return null;
    }
    return (
      <div className={`glide ${this.props.className} ${this.props.carouselName}`}>
        <div className={`glide__track`} data-glide-el="track">
          <ul className="glide__slides">{this.renderChildren()}</ul>
        </div>
        <div className={`glide__arrows`}>
          {this.props.renderCenterLeftControls ? (
            <div styleName={`center-left-controls`} className="slider-control-centerleft">
              {this.props.renderCenterLeftControls({
                previousSlide: this.previousSlide,
                nextSlide: this.nextSlide,
                goToSlide: this.goToSlide,
                totalSlides: this.props.children.length,
                currentSlide: this.state.currentSlide
              })}
            </div>
          ) : null}
          {this.props.renderCenterRightControls ? (
            <div styleName={`center-right-controls`} className="slider-control-centerright">
              {this.props.renderCenterRightControls({
                previousSlide: this.previousSlide,
                nextSlide: this.nextSlide,
                goToSlide: this.goToSlide,
                totalSlides: this.props.children.length,
                currentSlide: this.state.currentSlide
              })}
            </div>
          ) : null}
        </div>
        {this.props.renderBottomCenterControls ? (
          <div
            styleName={`bottom-center-controls`}
            className="slider-control-bottomcenter glide__bullets"
            data-glide-el="controls[nav]"
          >
            {this.props.renderBottomCenterControls({
              previousSlide: this.previousSlide,
              nextSlide: this.nextSlide,
              goToSlide: this.goToSlide,
              totalSlides: this.props.children.length,
              currentSlide: this.state.currentSlide,
              className: this.props.bulletClassName
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

Carousel.displayName = "Carousel";

Carousel.propTypes = {
  carouselName: PropTypes.string.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  options: PropTypes.object.isRequired,
  className: PropTypes.string,
  bulletClassName: PropTypes.string,
  renderCenterLeftControls: PropTypes.func,
  renderCenterRightControls: PropTypes.func,
  renderBottomCenterControls: PropTypes.func
};

const renderCenterLeftControls = ({ previousSlide }) => (
  <button onClick={previousSlide} className="pre-button">
    <PreButton />
  </button>
);

renderCenterLeftControls.propTypes = {
  previousSlide: PropTypes.func
};

const renderCenterRightControls = ({ nextSlide }) => (
  <button onClick={nextSlide} className="next-button">
    <NextButton />
  </button>
);

renderCenterRightControls.propTypes = {
  nextSlide: PropTypes.func
};

const renderBottomCenterControls = ({ goToSlide, totalSlides }) => {
  return Array(totalSlides)
    .fill(1)
    .map((item, index) => {
      return <div onClick={() => goToSlide(index)} key={index} styleName="carousel__bullet" />;
    });
};

renderBottomCenterControls.propTypes = {
  gotoSlide: PropTypes.func,
  currentSlide: PropTypes.number,
  totalSlides: PropTypes.number
};

Carousel.defaultProps = {
  renderCenterLeftControls,
  renderCenterRightControls,
  renderBottomCenterControls,
  className: "",
  carouselName: "sme-carousel",
  options: {
    autoplay: 3000,
    type: "carousel"
  }
};
