import React from "react";
import { Link, SocialShare } from "@quintype/components";
import { SocialShareTemplateHover } from "../../story-templates/social-share-hover";
import "./story-card.m.css";
import { PlayButton } from "../../atoms/play-button";
import { Author } from "../../atoms/author";
import { getSubstring } from "../../utils/utils";
import PropTypes from "prop-types";
import { OptimizedImage } from "../optimized-image";
import humanizeDate from "../../utils/time-format";

function StoryCard({
  story = {},
  config,
  imageAspectRatio = "16,9",
  setHorizontalLayout = false,
  showSummary = false,
  className = "",
  mobileAspectRatio,
  desktopAspectRatio
}) {
  // Support both collection story and normal story object.
  const storyObject = story.type === "story" ? story.story : story;
  const formattedAspectRatio = JSON.parse(`[${imageAspectRatio}]`);

  const imageAspectRatioStyle = {
    paddingTop: `${(formattedAspectRatio[1] / formattedAspectRatio[0]) * 100}%`
  };

  function getElementStyle(element) {
    switch (element) {
      case "link":
        return setHorizontalLayout ? "story-card-horizontal" : "story-card-vertical";
      case "image":
        return setHorizontalLayout ? "image-horizontal" : "image";
      case "content":
        return setHorizontalLayout ? "content-horizontal" : "content";
      case "section":
        return setHorizontalLayout ? "section-name-horizontal" : "section-name";
      case "summary":
        return setHorizontalLayout ? "summary-horizontal" : "summary";
      case "play_btn":
        return setHorizontalLayout ? "play-button-horizontal" : "play-button";
      case "title":
        return setHorizontalLayout ? "title title-horizontal" : "title";
    }
  }

  return (
    <div styleName={"story-card__wrapper"}>
      <div styleName="social-share">
        <SocialShare
          template={SocialShareTemplateHover}
          color="#1c1c1c"
          title={storyObject.headline}
          url={storyObject.slug}
        />
      </div>
      <Link styleName={`${getElementStyle("link")} story-card`} className={className} href={`/${storyObject.slug}`}>
        <div styleName="figure_wrapper">
          <OptimizedImage
            style={imageAspectRatioStyle}
            styleName={`${getElementStyle("image")}`}
            slug={storyObject["hero-image-s3-key"]}
            metadata={storyObject["hero-image-metadata"]}
            alt={storyObject.headline}
            aspectRatio={formattedAspectRatio}
            mobileAspectRatio={mobileAspectRatio}
            desktopAspectRatio={desktopAspectRatio}
          >
            {storyObject["story-template"] === "video" && (
              <div styleName={`${getElementStyle("play_btn")}`}>
                {" "}
                <PlayButton type="small" />{" "}
              </div>
            )}
          </OptimizedImage>
        </div>
        <div styleName={`${getElementStyle("content")}`} className="sectionpage-details">
          <h3 styleName={`${getElementStyle("title")}`}>{storyObject.headline.replace(/&nbsp;/gi, "")}</h3>
          <div styleName="author-name-date">
            <time styleName="story-published-date">{humanizeDate(storyObject["last-published-at"])}</time>
            <span styleName="divider">/</span>
            <Author authorName={storyObject["author-name"]} />
          </div>
          {showSummary &&
            storyObject.subheadline && (
              <h6 styleName={`${getElementStyle("summary")}`}>
                {getSubstring(storyObject.subheadline, 85).replace(/&nbsp;/gi, "")}
              </h6>
            )}
        </div>
      </Link>
    </div>
  );
}

export { StoryCard };

StoryCard.propTypes = {
  className: PropTypes.string,
  story: PropTypes.shape({
    "author-name": PropTypes.string,
    "contributor-role": PropTypes.string,
    headline: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    "hero-image-metadata": PropTypes.shape({
      height: PropTypes.number,
      width: PropTypes.number,
      "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
    }),
    "story-template": PropTypes.string,
    "hero-image-s3-key": PropTypes.string,
    "hero-image-caption": PropTypes.string,
    engagement: PropTypes.shape({
      total: PropTypes.number
    })
  }),
  setHorizontalLayout: PropTypes.bool,
  imageAspectRatio: PropTypes.string,
  showSummary: PropTypes.bool,
  config: PropTypes.object,
  mobileAspectRatio: PropTypes.string,
  desktopAspectRatio: PropTypes.string
};
