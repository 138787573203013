import React from "react";
import PropTypes from "prop-types";
import { ResponsiveSource, ResponsiveImage } from "@quintype/components";

function OptimizedImage({
  mobileAspectRatio,
  desktopAspectRatio,
  aspectRatio = [1200, 630],
  metadata = {},
  alt = "",
  slug = "",
  sizes = "(max-width: 500px) 98vw, (max-width: 768px) 48vw, 23vw",
  className = "",
  style = {},
  children,
  mobileWidths = [250, 480, 640],
  desktopWidths = [250, 320, 480, 640, 1280, 1600],
  defaultWidth = 640
}) {
  // 1200x600 is the size of most of the migrated hero images for SME.
  // Mocking the focus point so that aspectRatio will work.
  const imgMetaData = Object.assign(
    {},
    {
      "focus-point": [300, 600],
      width: 1200,
      height: 600
    },
    metadata
  );
  return (
    <figure style={style} className={className}>
      <picture>
        {/* Desktop WebP */}
        <ResponsiveSource
          slug={slug}
          alt={alt}
          media="(min-width: 768px)"
          metadata={imgMetaData}
          aspectRatio={desktopAspectRatio || aspectRatio}
          type="image/webp"
          imgParams={{ auto: ["format", "compress"], fm: "webp" }}
          defaultWidth={defaultWidth}
          widths={desktopWidths}
          sizes={sizes}
        />
        {/* Desktop Jpeg */}
        <ResponsiveSource
          slug={slug}
          alt={alt}
          media="(min-width: 768px)"
          metadata={imgMetaData}
          aspectRatio={desktopAspectRatio || aspectRatio}
          type="image/jpeg"
          imgParams={{ auto: ["format", "compress"] }}
          defaultWidth={defaultWidth}
          widths={desktopWidths}
          sizes={sizes}
        />
        {/* Mobile WebP */}
        <ResponsiveSource
          slug={slug}
          alt={alt}
          metadata={imgMetaData}
          aspectRatio={mobileAspectRatio || aspectRatio}
          type="image/webp"
          imgParams={{ auto: ["format", "compress"], fm: "webp" }}
          defaultWidth={defaultWidth}
          widths={mobileWidths}
          sizes="98vw"
        />
        {/* Mobile JPEG */}
        <ResponsiveImage
          slug={slug}
          alt={alt}
          metadata={imgMetaData}
          aspectRatio={mobileAspectRatio || aspectRatio}
          type="image/jpeg"
          imgParams={{ auto: ["format", "compress"] }}
          defaultWidth={defaultWidth}
          widths={mobileWidths}
          sizes="98vw"
        />
      </picture>
      {children}
    </figure>
  );
}

export { OptimizedImage };

OptimizedImage.propTypes = {
  mobileAspectRatio: PropTypes.array,
  desktopAspectRatio: PropTypes.array,
  aspectRatio: PropTypes.array,
  metadata: PropTypes.object,
  slug: PropTypes.string,
  alt: PropTypes.string,
  sizes: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  mobileWidths: PropTypes.array,
  desktopWidths: PropTypes.array,
  defaultWidth: PropTypes.number
};
