import includes from "lodash/includes";
const Moment = require("moment");

export default function humanizeDate(timestamp, format = null) {
  Moment.relativeTimeThreshold("s", 60);
  Moment.relativeTimeThreshold("m", 60);
  Moment.relativeTimeThreshold("h", 24);
  const utcTime = Moment.utc(timestamp);

  const relativeTime = utcTime.fromNow();

  const startOfDay = Moment().startOf("day");
  return formatTimeStamp(utcTime, relativeTime, startOfDay, format);
}

function formatTimeStamp(utcTime, relativeTime, startOfDay) {
  if (utcTime > startOfDay) {
    return relativeTime;
  } else {
    relativeTime = utcTime.local().format("D MMM YYYY");
  }
  return relativeTime;
}
